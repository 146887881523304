import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import {
    createNotificationPreferencesSetEvent,
    createSavePushSubscriptionToStateEvent,
} from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import { useDispatch } from 'react-redux';
import { Button, ButtonStyles, Text, Icon } from 'ComponentLibrary';
import Constants from 'Constants';
import PWAService from 'services/PWAService';
import NotificationPreferencesForm from './NotificationsPreferencesForm';
import slide1Img from '../../assets/my-home-logo-360w.png';
import slide2Img from '../../assets/Slide2-desktop.png';
import slide4Img from '../../assets/Slide4-desktop.png';

const DesktopWelcome = (props) => {
    const { submitWelcomeScreen, modalOpen } = props;
    const [notficationsData, setNotificationsData] = useState({});
    const settings = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        draggable: false,
        adaptiveHeight: true,
        swipeToSlide: false,
        swipe: false,
    };
    const dispatch = useDispatch();

    const customSlider = useRef();

    const gotoNext = () => {
        customSlider.current.slickNext();
    };
    return (
        <Slider {...settings} ref={customSlider}>
            <div className="welcome-screen screen1" data-testid="screen1">
                <div className="welcome-image-wrapper">
                    <img height="360" width="360" src={slide1Img} alt="" />
                </div>
                <div className="welcome-content-wrapper">
                    <div className="welcome-content">
                        <Text type="block" variant="large">
                            {Constants.welcome.desktop.screen1.mainText}
                        </Text>
                        <Text type="block" variant="regular">
                            {Constants.welcome.desktop.screen1.subText}
                        </Text>
                        <Button
                            selected
                            as="button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.welcome.buttonText.next}
                            submit
                            onClick={gotoNext}
                        />
                    </div>
                </div>
            </div>

            <div className="welcome-screen screen2" data-testid="screen2">
                <div className="welcome-image-wrapper">
                    <img src={slide2Img} alt="" />
                </div>
                <div className="welcome-content-wrapper">
                    <div className="welcome-content">
                        <Text type="block" variant="large">
                            {Constants.welcome.desktop.screen2.mainText}
                        </Text>
                        <Text type="block" variant="regular">
                            {Constants.welcome.desktop.screen2.subText}
                        </Text>
                        <Button
                            selected
                            as="button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.welcome.buttonText.next}
                            submit
                            onClick={gotoNext}
                        />
                    </div>
                </div>
            </div>

            <div className="welcome-screen screen3" data-testid="screen3">
                <div className="welcome-content-wrapper welcome-content-wrapper--no-image">
                    <div className="welcome-content welcome-content--no-image">
                        <div className="welcome-content__icon">
                            <Icon
                                icon="alert"
                                circle="portal-bg-color"
                                size={2.5}
                            />
                            <div className="welcome-content__alert-dot" />
                        </div>
                        <Text type="block" variant="large">
                            {Constants.welcome.desktop.screen3.mainText}
                        </Text>
                        <Text type="block" variant="regular">
                            {Constants.welcome.desktop.screen3.subText}
                        </Text>
                        <NotificationPreferencesForm
                            modalOpen={modalOpen}
                            uniqueId="2"
                            onUpdate={(data) => {
                                setNotificationsData(data);
                            }}
                        />
                        <Button
                            selected
                            testId="notifications-preferences-button"
                            as="button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.welcome.buttonText.next}
                            submit
                            onClick={async (e) => {
                                if (notficationsData.updateFlag) {
                                    dispatch(
                                        createNotificationPreferencesSetEvent(
                                            notficationsData
                                        )
                                    );
                                }
                                const subscription = await PWAService.getPushNotificationSubscription();
                                if (
                                    subscription === null &&
                                    notficationsData &&
                                    subscription === null &&
                                    notficationsData.updateFlag &&
                                    notficationsData.enablePush
                                ) {
                                    PWAService.subscribeToPushNotifications(
                                        notficationsData
                                    );
                                } else if (
                                    subscription &&
                                    subscription.endpoint &&
                                    notficationsData.enablePush
                                ) {
                                    PWAService.savePushSubscription(
                                        subscription
                                    ).then((res) => {
                                        if (
                                            res.data.notificationSubscriptionId
                                        ) {
                                            dispatch(
                                                createSavePushSubscriptionToStateEvent(
                                                    res.data.notificationSubscriptionId,
                                                    subscription.endpoint,
                                                )
                                            );
                                        }
                                    }).catch(() => {

                                    })
                                }
                                gotoNext();
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="welcome-screen screen4" data-testid="screen4">
                <div className="welcome-image-wrapper">
                    <img src={slide4Img} alt="" />
                </div>
                <div className="welcome-content-wrapper">
                    <div className="welcome-content">
                        <Text type="block" variant="large">
                            {Constants.welcome.desktop.screen4.mainText}
                        </Text>
                        <Text type="block" variant="regular">
                            {Constants.welcome.desktop.screen4.subText}
                        </Text>
                        <Button
                            selected
                            as="button"
                            testId="desktop-welcome-done-button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.welcome.buttonText.done}
                            submit
                            onClick={submitWelcomeScreen}
                        />
                    </div>
                </div>
            </div>
        </Slider>
    );
};

export default DesktopWelcome;
