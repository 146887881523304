import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import PWAService from 'services/PWAService';
import './UserProfileDropdown.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { createShowFeedbackModalAction } from 'redux/actions/Feedback';
import {
    createCloseUserProfileDropdownAction,
    createOpenUserProfileDropdownAction,
    createAppIsInstallableEvent,
    createNotificationPreferencesSetEvent,
    createOpenChangeLotPageAction,
    createLogOutAction,
    createSavePushSubscriptionToStateEvent,
    createAddALotAction,
    createEnterDeleteModeAction,
    createExitDeleteModeAction,
} from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import Utils from 'utilities/Utils';
import WithCondition from 'hoc/WithCondition';
import {
    useHaveUser,
    useOutsideAlerter,
    useIsAdmin,
} from 'utilities/HooksAndSelectors';
import { Button, Text, ButtonStyles, MIModal, Icon, BackButton } from 'ComponentLibrary';
import LotListItem from 'App/ContentComponents/LotListItem';
import Constants from 'Constants';
import Links from 'components/Links';
import installApp from 'pwaInstall';
import { Event } from 'components/Analytics';

import NotificationPreferencesForm from '../../../pages/Dashboard/NotificationsPreferencesForm';
import { isSafari } from 'react-device-detect';

export default function UserProfileDropdown(props) {
    const haveUser = useHaveUser();
    const [installable, setInstallable] = useState(null);
    const [showNotificationsModal, setNotificationsModal] = useState(false);
    const [notficationsData, setNotificationsData] = useState(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [showSuccessIcon, setShowSuccessIcon] = useState(false);
    const { mode, testId } = props;
    const Tag = mode === 'modal' ? 'div' : 'section';
    const userState = useSelector((state) => state.userState);
    const { userDetails, profileAndSettings, progressContent } = userState;
    const features = useSelector((state) => state.featuresState);
    const { canHaveMultipleLots } = features;
    const { lots } = progressContent;
    const { activeLotId } = profileAndSettings;
    const lot = lots && lots[activeLotId];


    const navigate = useNavigate();
    const location = useLocation();
    const isAdmin = useIsAdmin();
    const dispatch = useDispatch();

    const wrapperRef = useRef(null);
    const { profileDropDownOpen, appIsInstallable, removeLotMode } = profileAndSettings;

    const closeDropdown = () => {
        navigate(location.pathname);
        const action = createCloseUserProfileDropdownAction();
        dispatch(action);
        return false;
    };

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleInstallable() {
            if (installable === null) {
                dispatch(createAppIsInstallableEvent());
                setInstallable(true);
            }
        }
        // Bind the event listener
        if (installable === null) {
            window.addEventListener('beforeinstallprompt', handleInstallable);
        }
        return () => {
            // Unbind the event listener on clean up
            window.removeEventListener(
                'beforeinstallprompt',
                handleInstallable
            );
        };
    }, [installable, dispatch]);

    useOutsideAlerter(wrapperRef, (whatWasClicked) => {
        const parentIsBadge = Utils.findParent(
            whatWasClicked,
            'navigation-header-user-container'
        );
        if (profileDropDownOpen && parentIsBadge === null) {
            Event(
                'user-profile-dropdown',
                'close',
            );
            closeDropdown();
        }
    });

    if (!haveUser) return null;
    const { lotAssociations, accountProfile, user, accessToken } = userDetails;
    const { firstName, lastName } = accountProfile;
    let installedMode = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        installedMode = true;
    }

    if (
        location.hash === '#userSettings' &&
        mode === 'modal' &&
        !profileDropDownOpen
    ) {
        const action = createOpenUserProfileDropdownAction();
        dispatch(action);
    }

    const handleLogOut = (e) => {
        dispatch(createLogOutAction(accessToken, isAdmin));
        navigate('/login?logout=true');
        e.preventDefault();
        return false;
    };

    const handleNotificationSettings = () => {
        setNotificationsModal(true);
    };

    const handleCloseNotificationsModal = () => {
        setNotificationsModal(false);
        setShowSuccessIcon(false);
    };

    const handleSaveButton = (value) => {
        setIsSaveDisabled(value);
    };

    const handleSuccessIcon = (value) => {
        setShowSuccessIcon(value);
    };
    return (
        <WithCondition
            condition={mode === 'modal'}
            wrapper={(children) => (
                <CSSTransition
                    onEnter={(el) => {
                        el.setAttribute('open', '');
                    }}
                    onExited={(el) => {
                        try {
                            el.close();
                        } catch (e) {
                            // simply because browsers don't all support this
                        }
                        el.removeAttribute('open');
                    }}
                    in={profileDropDownOpen && mode === 'modal'}
                    appear={profileDropDownOpen}
                    exit={!profileDropDownOpen}
                    timeout={200}
                    classNames="fade"
                //funmountOnExit={true}
                >
                    {children}
                </CSSTransition>
            )}
        >
            <Tag
                data-testid={`${testId}`}
                className={`user-profile-dropdown user-profile-dropdown--${mode}`}
                ref={wrapperRef}
            >
                <div className="user-profile-dropdown__outer-wrap">
                    <div className="user-profile-dropdown__inner-wrap">
                        <div className="user-profile-dropdown__name">
                            <BackButton />
                            <Text variant={mode === 'modal' ? 'regular' : 'medium'} type="inline" family={mode === 'modal' ? 'museo' : ''}>
                                {firstName} {lastName}
                            </Text>
                            {isAdmin && lot && (
                                <Text variant={mode === 'modal' ? 'regular' : 'medium'} type="block">
                                    <br />Lot Id: {activeLotId}<br />{lot.streetAddress}
                                </Text>
                            )}
                        </div>
                        <div className="user-profile-dropdown__content-wrap">
                            <Text>
                                <Button
                                    href={`${window._env_.REACT_APP_SITECORE_ROOT}/account/account-settings`}
                                    as="link"
                                    target="_blank"
                                    style={ButtonStyles.TextOnlyLink}
                                >
                                    <Text type="inline" variant="regular">
                                        {Constants.profileDropDown.settings}
                                    </Text>
                                </Button>
                            </Text>

                            <Text>
                                <Button
                                    href="#"
                                    as="link"
                                    testId="notification-settings-link-button"
                                    style={ButtonStyles.TextOnlyLink}
                                    onClick={handleNotificationSettings}
                                >
                                    <Text type="inline" variant="regular">
                                        {Constants.profileDropDown.notifications}
                                    </Text>
                                </Button>
                            </Text>


                            <Text>
                                <Button
                                    onClick={(e) => {
                                        dispatch(createShowFeedbackModalAction());
                                    }}
                                    testId="notification-show-feedback-button"
                                    as="button"
                                    style={ButtonStyles.TextOnlyLink}
                                >
                                    <Text type="inline" variant="regular">
                                        Feedback
                                    </Text>
                                </Button>
                            </Text>


                            <MIModal
                                show={showNotificationsModal}
                                closeModal={handleCloseNotificationsModal}
                                customClass="notification-settings-modal"
                                isHeader
                                modalSize="lg"
                                centered="true"
                            >
                                <div className="notification-settings-container" data-testid="notification-settings-container">
                                    <div className="notification-settings-content-wrapper">
                                        <div
                                            className="icon-container"
                                            style={{
                                                display: showSuccessIcon
                                                    ? 'none'
                                                    : 'block',
                                            }}
                                        >
                                            <Icon
                                                icon="alert"
                                                circle="portal-bg-color"
                                                size={2.5}
                                            />
                                            <div className="notification-settings__alert-dot" />
                                        </div>
                                        <div
                                            className="icon-container"
                                            style={{
                                                display: showSuccessIcon
                                                    ? 'block'
                                                    : 'none',
                                            }}
                                        >
                                            <Icon
                                                icon="successCheckmark"
                                                circle="portal-bg-color"
                                                size={2.5}
                                            />
                                        </div>

                                        <Text type="block" variant="large">
                                            {
                                                Constants.welcome.desktop.screen3
                                                    .mainText
                                            }
                                        </Text>
                                        <Text type="block" variant="regular">
                                            {
                                                Constants.welcome.desktop.screen3
                                                    .subText
                                            }
                                        </Text>
                                        <NotificationPreferencesForm
                                            uniqueId="1"
                                            modalOpen={true}
                                            onUpdate={(data) => {
                                                setNotificationsData(data);
                                                handleSaveButton(!data.updateFlag);
                                            }}
                                        />
                                        <Button
                                            selected
                                            as="button"
                                            testId="save-preferences-button"
                                            style={ButtonStyles.PrimaryButton}
                                            text={
                                                Constants.profileDropDown
                                                    .saveChanges
                                            }
                                            disabled={isSaveDisabled}
                                            onClick={async (e) => {
                                                dispatch(
                                                    createNotificationPreferencesSetEvent(
                                                        notficationsData
                                                    )
                                                );
                                                const subscription = await PWAService.getPushNotificationSubscription();
                                                if (
                                                    subscription === null &&
                                                    notficationsData &&
                                                    notficationsData.enablePush
                                                ) {
                                                    PWAService.subscribeToPushNotifications(
                                                        notficationsData
                                                    );
                                                } else if (subscription && notficationsData.enablePush) {
                                                    PWAService.savePushSubscription(
                                                        subscription
                                                    ).then((res) => {
                                                        if (
                                                            res.data.notificationSubscriptionId
                                                        ) {
                                                            dispatch(
                                                                createSavePushSubscriptionToStateEvent(
                                                                    res.data.notificationSubscriptionId,
                                                                    subscription.endpoint,
                                                                )
                                                            );
                                                        }
                                                    }).catch(() => {

                                                    })
                                                }
                                                handleSaveButton(true);
                                                handleSuccessIcon(true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </MIModal>
                        </div>
                        <div className="user-profile-dropdown__content-wrap">
                            {isAdmin && (
                                <>
                                    <Link
                                        to="/division-dashboard"
                                        data-testid="division-dash-admin-link"
                                        className="button secondary-button"
                                    >
                                        {Constants.profileDropDown.adminDashboard}
                                    </Link>
                                    <Link
                                        to="/lot-dashboard"
                                        data-testid="lot-dash-admin-link"
                                        className="button secondary-button"
                                    >
                                        {Constants.profileDropDown.adminLot}
                                    </Link>
                                </>
                            )}
                            {lotAssociations && (
                                <ul className="lot-list" data-testId="lot-list">
                                    {lotAssociations.map((lot, index) => (
                                        <LotListItem
                                            multiple={lotAssociations.length > 1}
                                            lot={lot}
                                            index={index}
                                            key={`lot-${index}`}
                                            testId="dropdown-lot-list-item"
                                        />
                                    ))}
                                </ul>
                            )}
                            <div className="user-profile-dropdown__add-remove-container">
                                {removeLotMode && (
                                    <Button
                                        as="button"
                                        testId="cancel-remove-lot-button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(
                                                createExitDeleteModeAction()
                                            );
                                        }}
                                        style={ButtonStyles.SecondaryButton}
                                        text={Constants.profileDropDown.cancel}
                                    />
                                )}
                                {(!removeLotMode && (canHaveMultipleLots ||
                                    !lotAssociations ||
                                    lotAssociations.length === 0)) && (
                                        <Button
                                            as="button"
                                            testId="add-lot-button"
                                            icon="plus"
                                            disabled={isAdmin}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate('/lot-association');
                                                dispatch(createAddALotAction())
                                                return false;
                                            }}
                                            style={ButtonStyles.SecondaryButton}
                                            text={Constants.profileDropDown.addLot}
                                        />
                                    )}
                                {!removeLotMode && (isAdmin || (lotAssociations && lotAssociations.length > 1)) && (
                                    <Button
                                        as="button"
                                        testId="enter-delete-mode-button"
                                        disabled={isAdmin}
                                        icon="minus"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(createEnterDeleteModeAction());
                                        }}
                                        style={ButtonStyles.SecondaryButton}
                                        text={Constants.profileDropDown.removeLot}
                                    />
                                )}
                            </div>
                            {isAdmin && (
                                <Button
                                    as="button"
                                    testId="open-change-lot-button"
                                    style={ButtonStyles.SecondaryButton}
                                    onClick={(e) => {
                                        const addCancel = true;
                                        const action = createOpenChangeLotPageAction(addCancel);
                                        dispatch(action);
                                        navigate('/find-lot');
                                        e.preventDefault();
                                        return false;
                                    }}
                                    text={Constants.profileDropDown.changeLot}
                                />
                            )}
                        </div>

                        {appIsInstallable && !installedMode && (
                            <div className="user-profile-dropdown__content-wrap" data-testid="install-app-wrapper">
                                <Text>
                                    <Button
                                        testId="install-app-button"
                                        onClick={() => {
                                            installApp();
                                        }}
                                        as="button"
                                        style={ButtonStyles.TextOnlyLink}
                                    >
                                        <Text type="inline" variant="regular">
                                            {Constants.profileDropDown.install}
                                        </Text>
                                    </Button>
                                </Text>
                            </div>
                        )}
                        <div className="user-profile-dropdown__content-wrap">
                            <Text>
                                <Button
                                    testId="log-out-button"
                                    onClick={handleLogOut}
                                    as="button"
                                    style={ButtonStyles.TextOnlyLink}
                                >
                                    <Text type="inline" variant="regular">
                                        {Constants.profileDropDown.logout}
                                    </Text>
                                </Button>
                            </Text>
                        </div>
                        <div className="user-profile-dropdown__name aqua">
                            <Links.PrivacyPolicyLink />
                            <span>{' • '}</span>
                            <Links.TermsOfUseLink />
                        </div>
                    </div>
                </div>
            </Tag>
        </WithCondition>
    );
}

UserProfileDropdown.defaultProps = {
    mode: 'modal',
    testId: null,
};

UserProfileDropdown.propTypes = {
    mode: PropTypes.string,
    testId: PropTypes.string,
};
