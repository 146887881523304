import React from 'react';
import Utils from 'utilities/Utils';

const Tab = (props) => {
    const {
        onClick,
        testId,
        index,
        active,
        title,
        first,
        last,
        slug,
        status,
    } = props;

    return (
        <li
            className={`tab tab--${status}`}
            aria-selected={active ? 'true' : null}
            role="tab"
            id={`tab-${index}`}
            data-testid={`tabs__tab--${testId}-${index}`}
        >
            <button
                className={`tab__button tab__button--${first && 'first'} tab__button--${last && 'last'} tab__button--${status} ${active ? 'tab__button--active' : ''}`}
                data-testid="test-tab-button"
                type="button"
                disabled={active ? 'disabled' : null}
                data-slug={slug}
                data-index={index}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                    return false;
                }}
            >

                <span className="tab__label" id={`tab-${slug}`}>{title}</span>
                <span className={`tab__status tab__status--${status}`}>{Utils.translateStatus(status)}</span>

            </button>
        </li>
    );
};

export default Tab;
