/* eslint-disable no-case-declarations */
/* eslint-disable indent */
import ActionTypes from 'redux/actions/ActionTypes';

const isValidServiceItem = ({ selectedSubCategory, selectedIssue, comments }) => selectedSubCategory && selectedIssue && comments && comments !== '';

const initialState = {
    claimsData: [],
    claimWithError: null,
    status: 'idle',
    requestId: null,
};

const WarrantyClaimsReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case ActionTypes.SignOut:
        case ActionTypes.LogOut:
            return {
                initialState,
            };
        case ActionTypes.SetOpenClaim:
            if (action.payload === '' || action.payload === null) {
                let haveError = false;
                state.claimsData.forEach((serviceItem) => {
                    if (!isValidServiceItem(serviceItem)) {
                        haveError = true;
                    }
                });
                if (!haveError) {
                    return {
                        ...state,
                        claimWithError: null,
                    };
                }
            }
            return state;
        case ActionTypes.ResetAfterSubmitSuccess:
            return initialState;
        case ActionTypes.RemoveExistingClaimsData:
            // we have submitted completely & told the API so
            return {
                ...initialState,
                status: 'completed',
            };
        case ActionTypes.ServiceRequestCompleted:
            return {
                ...state,
                status: 'completed',
            };
        case ActionTypes.SuccessfulWarrantySubmission:
            return {
                ...state,
                status: 'busy',
            };
        case ActionTypes.GetServiceRequestIdSuccess:
            const newClaimsWithServiceId = state.claimsData;
            newClaimsWithServiceId.forEach((claim) => claim.serviceRequestId = action.payload.requestId);
            return {
                ...state,
                claimsData: newClaimsWithServiceId,
                requestId: action.payload.requestId,
            };
        case ActionTypes.ServiceRequestIdAskedFor:
            return {
                ...state,
                requestId: 'requested',
            };
        case ActionTypes.ServiceItemBatchSubmitSuccess:
            return {
                ...state,
                status: 'idle',
            };
        case ActionTypes.ServiceItemSubmitRequest:
        case ActionTypes.SubmitServiceItem:
        case ActionTypes.ServiceItemSubmitSuccess:
        case ActionTypes.ServiceItemSubmitError:
            let index = action.type === ActionTypes.SubmitServiceItem ? 0 : action.payload;//probably need to change from 0 to first unsumbitted
            if (action.type === ActionTypes.ServiceItemSubmitRequest || action.type === ActionTypes.ServiceItemSubmitSuccess) {
                index = action.payload.idOrIndex;
            }
            const newServiceItem = state.claimsData[index];
            if (action.type === ActionTypes.ServiceItemSubmitRequest) {
                newServiceItem.submitTime = action.payload.time;
            }
            if (action.type === ActionTypes.ServiceItemSubmitSuccess) {
                newServiceItem.completedTime = action.payload.time;
            }
            newServiceItem.submissionStatus = action.type === ActionTypes.ServiceItemSubmitSuccess ? 'submitted' : 'submitting';
            if (action.type === ActionTypes.ServiceItemSubmitError) {
                newServiceItem.submissionStatus = 'error';
            }

            const newClaims = state.claimsData;
            newClaims[index] = newServiceItem;
            return {
                ...state,
                status: 'busy',
                newClaims,
            };
        case ActionTypes.LoadClaimDataFromFile:
            if (action.payload.claimData && Array.isArray(action.payload.claimData) && action.payload.claimData.length > 0) {
                return {
                    ...state,
                    claimsData: action.payload.claimData,
                };
            } else {
                return {
                    ...state,
                    claimsData: [],
                };
            }
        case ActionTypes.AddClaim:
            return {
                ...state,
                claimsData: [...state.claimsData || [], ...[action.payload]],
            };

        case ActionTypes.RemoveClaim:
            return {
                ...state,
                claimsData: [
                    ...state.claimsData.slice(
                        0,
                        state.claimsData.indexOf(action.payload)
                    ),
                    ...state.claimsData.slice(
                        state.claimsData.indexOf(action.payload) + 1
                    ),
                ],
            };

        case ActionTypes.UpdateClaimDetails:
            const claimToUpdate = state.claimsData.find((claim) => {
                return claim.categoryId === action.payload.id;
            });

            const isAnImageUpdate = action.payload.type === 'imageUrl' || action.payload.type === 'imageBase64' || action.payload.type === 'clearImage';
            const clearImage = action.payload.type === 'clearImage';
            const claimToUpdateIndex = state.claimsData.indexOf(claimToUpdate);
            let updatedClaim = null;
            if (!isAnImageUpdate) {
                updatedClaim = {
                    ...claimToUpdate,
                    ...{ [action.payload.type]: action.payload.value },
                };
            } else {
                if (action.payload.index === 'all') {
                    updatedClaim = {
                        ...claimToUpdate,
                        ...{ images: [{}, {}, {}, {}, {}, {}] },
                    };
                } else if (clearImage) {
                    const newImages = [...claimToUpdate.images];
                    newImages[action.payload.index] = {};
                    updatedClaim = {
                        ...claimToUpdate,
                        ...{ images: newImages },
                    };
                } else {
                    const newImages = Array.isArray(claimToUpdate.images) ? [...claimToUpdate.images] : [];
                    if (!newImages[action.payload.index]) {
                        newImages[action.payload.index] = {};
                    }
                    newImages[action.payload.index][action.payload.type] = action.payload.value;
                    updatedClaim = {
                        ...claimToUpdate,
                        ...{ images: newImages },
                    };
                }
            }

            const newClaimsArray = [...state.claimsData];
            newClaimsArray[claimToUpdateIndex] = updatedClaim;

            return {
                ...state,
                claimsData: newClaimsArray,
            };

        case ActionTypes.SetClaimWithError:
            return {
                ...state,
                claimWithError: action.payload,
            };

        case ActionTypes.UpdateWarrantyPageStatus:
            return {
                ...state,
                claimWithError: null,
            };

        default:
            return state;
    }
};

export default WarrantyClaimsReducer;
