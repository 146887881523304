import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { isMobile, isIOS } from 'react-device-detect';
import './NotificationsDropdown.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    createGetNotificationsAction,
    createCloseNotificationsDropdownAction,
    createViewNotificationAction,
    createSetOpenNotificationAction,
} from 'redux/actions/Notifications/NotificationsActions';
import Utils from 'utilities/Utils';
import WithCondition from 'hoc/WithCondition';
import {
    useHaveUser,
    useIsAdmin,
    useWindowSize,
} from 'utilities/HooksAndSelectors';
import { Text, Icon } from 'ComponentLibrary';
import Constants from 'Constants';
import { Accordion } from 'react-bootstrap';
import NotificationsDropdownCard from './ContentComponents/NotificationsDropdownCard'


function useOutsideAlerter(ref, callback) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event.target);
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
}

export default function NotificationsDropdown(props) {
    const haveUser = useHaveUser();
    const isAdmin = useIsAdmin();
    const navigate = useNavigate();
    const location = useLocation();
    const notificationsState = useSelector((state) => state.notificationsState);
    const userState = useSelector((state) => state.userState);
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const { mode } = props;

    const {
        notifications,
        unreadNotifications,
        notificationsDropDownOpen,
        openNotification,
    } = notificationsState;


    if (notifications === null && userState.userDetails.activeLotId) {
        dispatch(createGetNotificationsAction());
    }

    useOutsideAlerter(wrapperRef, (whatWasClicked) => {
        const parentIsToggle = Utils.findParent(
            whatWasClicked,
            'navigation-header-notifications-container'
        );
        const parentIsInput = Utils.findParent(
            whatWasClicked,
            'field-wrap'
        );

        const parentIsModal = Utils.findParent(whatWasClicked, 'notifications-dropdown--modal');

        if (isMobile && notificationsDropDownOpen && parentIsInput === null && parentIsToggle === null) {
            closeDropdown();
        } else if (notificationsDropDownOpen && parentIsToggle === null && parentIsInput === null && parentIsModal === null) {
            closeDropdown();
        }
    });
    const handleTouchMove = (e) => {
        const wrap = Utils.findParent(
            e.target,
            'notifications-dropdown__inner-wrap'
        );
        const shouldPreventDefault = wrap === null;
        if (shouldPreventDefault) e.preventDefault();
    };
    useEffect(() => {
        if (isIOS && notificationsDropDownOpen) {
            window.addEventListener('touchmove', handleTouchMove, {
                passive: false,
            });
        }
        return () => {
            window.removeEventListener('touchmove', handleTouchMove);
        }
    }, [notificationsDropDownOpen]);

    const closeDropdown = () => {
        navigate(location.pathname);
        dispatch(createCloseNotificationsDropdownAction());
        dispatch(createGetNotificationsAction());
        dispatch(createSetOpenNotificationAction(null));
        return false;
    };

    const handleAccordionClick = (notification) => {
        const isUnreadNotification = unreadNotifications.indexOf(notification) > -1;
        if (openNotification === notification.notificationId) {
            dispatch(createSetOpenNotificationAction(null));
        } else {
            (!isAdmin && isUnreadNotification) && setTimeout(() => {
                dispatch(createViewNotificationAction(notification));
            }, 2000);
            dispatch(createSetOpenNotificationAction(notification.notificationId));
        }
        return false;
    };

    const HandleKeyUp = (event) => {
        if (event.keyCode === 27 && notificationsDropDownOpen) {
            closeDropdown();
        }
    };

    const HandleMobileClose = (event) => {
        if (isMobile && notificationsDropDownOpen) {
            const isMobileWrapper = event.target.classList.contains(
                'notifications-dropdown--mobile'
            );
            if (isMobileWrapper) {
                closeDropdown();
            }
        }
    };
    const [innerWidth] = useWindowSize();
    useEffect(() => {
        document.addEventListener('keyup', HandleKeyUp);
        document.addEventListener('mousedown', HandleMobileClose);

        return () => {
            document.removeEventListener('keyup', HandleKeyUp);
            document.removeEventListener('mousedown', HandleMobileClose);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsDropDownOpen]);

    if (!haveUser || !notifications || notifications === 'requested') return null;

    return (
        <WithCondition
            condition={mode !== 'page'}
            wrapper={(children) => (
                <CSSTransition
                    onEnter={(el) => {
                        el.setAttribute('open', '');
                        el.querySelectorAll(Utils.focusable)[0] && el.querySelectorAll(Utils.focusable)[0].focus();
                    }}
                    onExited={(el) => {
                        try {
                            el.close();
                        } catch (e) {
                            // simply because browsers don't all support this
                        }
                        el.removeAttribute('open');
                    }}
                    in={notificationsDropDownOpen}
                    appear={notificationsDropDownOpen}
                    exit={!notificationsDropDownOpen}
                    timeout={200}
                    classNames="fade"
                //unmountOnExit={true}
                >
                    {children}
                </CSSTransition>
            )}
        >
            <section
                className={`notifications-dropdown ${isMobile ? 'notifications-dropdown--mobile' : ''} `}
                data-testid="notifications-dropdown"
                ref={wrapperRef}
            >
                <div className="notifications-dropdown__inner-wrap">
                    <div className={`notifications-header`}>
                        {(isMobile || innerWidth < 764) && (
                            <button className="mobile-notifications-dropdown-close-button" data-testid="mobile-notifications-dropdown-close-button" onClick={closeDropdown}>
                                <Icon
                                    size={0.75}
                                    icon="closeIcon"
                                    color="black"
                                />
                            </button>
                        )}
                        <Text family="museo">
                            {Constants.notificationsDropdown.title}
                        </Text>
                        <div className="notifications-total">
                            {Array.isArray(unreadNotifications) ? unreadNotifications.length : 0}
                        </div>
                    </div>

                    <div className="notifications-container">
                        <Accordion>
                            {notifications &&
                                notifications.map((notification) => {
                                    const read = unreadNotifications.indexOf(notification) !== -1;
                                    return (
                                        <div
                                            className={`notifications-dropdown-card-container ${read
                                                ? 'unread'
                                                : 'read'
                                                }`}
                                            data-testid="notifications-dropdown-card-container"
                                            key={`notifications-card-${notification.notificationId}`}
                                        >
                                            <NotificationsDropdownCard
                                                notification={notification}
                                                read={read}
                                                handleClick={handleAccordionClick}
                                                isOpen={openNotification === notification.notificationId}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Accordion>
                    </div>
                </div>
            </section>
        </WithCondition>
    );
}

NotificationsDropdown.defaultProps = {
    mode: 'modal',
};

NotificationsDropdown.propTypes = {
    mode: PropTypes.string,
};
