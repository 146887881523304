import React from 'react';
import PropTypes from 'prop-types';
import './ChecklistDropdownCard.scss';
import { Text, Icon, Input } from 'ComponentLibrary';
import Constants from 'Constants';
import { Link, useNavigate } from 'react-router-dom';
import { createOpenChecklistModalAction } from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import { useDispatch } from 'react-redux';
import Utils from 'utilities/Utils';
import { useIsAdmin } from 'utilities/HooksAndSelectors';

export default function ChecklistDropdownCard(props) {
    const {
        task,
        testId,
        handleComplete,
        toComplete,
        mode,
        isPersonal,
    } = props;
    const { title, taskId, occursText, dueDate, dueDateStatus } = task;
    const dispatch = useDispatch();
    const isAdmin = useIsAdmin();
    const navigate = useNavigate();
    const completeTask = () => {
        handleComplete(task);
    };
    let dateString = '';
    if (dueDate) {
        const dateHalf = dueDate.split('T')[0]; //2024-03-06
        const datePieces = dateHalf.split('-');
        const d = new Date(dueDate);//.parsenew Date();datePieces[0], datePieces[1], datePieces[2]);
        //d.setUTCMonth(datePieces[1] - 1);
        dateString = `${Utils.day(d)}, ${Utils.month(
            datePieces[1] - 1,
            false
        )} ${parseInt(datePieces[2], 10)}, ${datePieces[0]}`;
    }

    const buildDueDateText = () => {
        if (isPersonal && !dateString) {
            return (
                <p className="single-task__due-date">
                    {Constants.checklistModal.noDueDateText}
                </p>
            );
        }

        return dateString !== '' ? (
            <React.Fragment>
                {Constants.checklistDropdown.dueDate}{' '}
                {dateString}
            </React.Fragment>
        ) : (
            <React.Fragment>
                {Constants.checklistDropdown.phaseStarter}{' '}
                {occursText}
            </React.Fragment>
        )
    };

    const content = (
        <>
            <div
                className="complete-input-container"
                data-testid="complete-input-container"
                onClick={(e) => {
                    if (mode === 'page') {
                        e.stopPropagation();
                    }
                }}
            >
                <Input
                    id={`checklist-task-${taskId}${mode}`}
                    testId={`checklist-task-${taskId}`}
                    type="checkbox"
                    disabled={isAdmin}
                    name={`checklist-task-card-${taskId}`}
                    label="Mark Complete"
                    extraClasses={`input-wrap--task ${toComplete && 'input-wrap--task-complete'
                        }`}
                    extraLabelTextClasses="visuallyhidden"
                    extraCheckboxClasses={`checkbox--task ${toComplete && 'checkbox--task-complete'
                        }`}
                    extraFieldWrapClasses="field-wrap--task"
                    extraCheckboxIndicatorClasses={`checkbox__indicator--task ${toComplete && 'checkbox__indicator--task-complete'
                        }`}
                    extraInputClasses={`input--task ${toComplete && 'input--task-complete'
                        }`}
                    animated
                    onChange={completeTask}
                    checked={toComplete}
                />
            </div>

            <div className="checklist-dropdown-card-content">
                <Text variant="small" color="tundora">
                    {title}
                </Text>
                <span
                    className={`checklist-card-phase-text ${!toComplete ? Utils.slugify(dueDateStatus) : ''
                        }`}
                >
                    <Text variant="extra-small">
                        {buildDueDateText()}
                    </Text>
                </span>
            </div>

            <Icon classsize={1} color="neutral-dark" icon="thinArrow" />
        </>
    );
    return mode === 'page' ? (
        <Link
            data-testid={testId}
            className="checklist-dropdown-card"
            onClick={() => {
                window.scrollTo(0, 0);
            }}
            to={`/checklist/${taskId}`}
        >
            {content}
        </Link>
    ) : (
        <button
            type="button"
            className="checklist-dropdown-card"
            data-testid={testId}
            onClick={(e) => {
                if (!Utils.findParent(e.target, 'field-wrap')) {
                    navigate(`/dashboard/checklist/${taskId}`);
                    dispatch(createOpenChecklistModalAction(taskId));
                }
            }}
        >
            {content}
        </button>
    );
}

ChecklistDropdownCard.defaultProps = {
    testId: 'checklist-dropdown-card',
    task: null,
    handleComplete: null,
    toComplete: false,
};

ChecklistDropdownCard.propTypes = {
    testId: PropTypes.string,
    mode: PropTypes.string.isRequired,
    task: PropTypes.object,
    handleComplete: PropTypes.func,
    toComplete: PropTypes.bool,
};
