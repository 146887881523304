import ActionTypes from 'redux/actions/ActionTypes';
import { put, call, select } from 'redux-saga/effects';
import WarrantyService from 'services/WarrantyService';
import {
  getUserProfileAndSettings,
  getWarranty,
  getUser,
  getWarrantyServiceItems,
} from 'redux/sagas/selectors';
import Utils from 'utilities/Utils';
import { assignCategories } from 'redux/sagas/sagaHelpers/warrantyHelpers';
import { _sendRequest } from 'redux/sagas/sagaHelpers/generalHelpers';
import { buildErrorObject } from 'redux/sagas/sagaHelpers/userHelpers';

async function fetchCategories(data = {}) {
  try {
    return await _sendRequest(data, WarrantyService.getCategories);
  } catch (error) {
    return buildErrorObject(error);
  }
}

async function getServiceRequestId(possibleRequestIdAndLot) {
  // just forcing ti to be an int
  possibleRequestIdAndLot = {
    lotId: parseInt(possibleRequestIdAndLot.lotId.toString()),
  }
  try {
    return await _sendRequest(possibleRequestIdAndLot, WarrantyService.getServiceRequestId, true);
  } catch (error) {
    return buildErrorObject(error);
  }
}

async function fetchCategoryData(data) {
  try {
    return await _sendRequest(data, WarrantyService.getCategoryData);
  } catch (error) {
    return buildErrorObject(error);
  }
}

async function submitClaim(data) {
  try {
    const r = await _sendRequest(data, WarrantyService.submitClaim, false, true);
    return r;
  } catch (error) {
    return buildErrorObject(error);
  }
}

export function* handleSubmitWarrantyClaimSaga(index) {
  const warrantyState = yield select(getWarranty);
  const userProfileAndSettings = yield select(getUserProfileAndSettings);
  const { activeLotId: lotId } = userProfileAndSettings;
  const { claimsData } = warrantyState.warrantyClaims;
  const data = claimsData[index];
  const {
    serviceRequestId,
    categoryId,
    categoryData,
    selectedIssue,
    modelNumber,
    selectedSubCategory,
    images,
  } = data;
  const singleSelectedCategoryData = categoryData.filter(
    (cat) => cat.categoryId === categoryId &&
      (cat.description === selectedSubCategory || cat.displayName === selectedSubCategory)
  )[0];

  const issue = singleSelectedCategoryData.issues.filter((issue) => issue.name === selectedIssue || issue.displayName == selectedIssue)[0];// && issue.subCategoryDescription === selectedSubCategory;
  const { subCategoryId, id } = issue || {
    subCategoryId: singleSelectedCategoryData.id,
    id: null,
  };

  const attachments = images.map(({ imageBase64 }, imageIndex) => {
    if (imageBase64 === null || imageBase64 === undefined || imageBase64 === '') {
      return null;
    }
    return {
      "attachment": imageBase64,
      "attachmentFilename": `claim-${index}-image-${imageIndex}.jpg`,
    }
  });
  const dataToSend = {
    serviceRequestId,
    categoryId,
    subCategoryId,
    lotId,
    issueId: id,
    modelNumber,
    description: data.comments,
    attachments: attachments.filter((item) => item !== null),
    subCategoryName: selectedSubCategory,
  };


  const submitClaimCompleteResponse = yield call(submitClaim, dataToSend);
  return submitClaimCompleteResponse;
}

export function* categoriesRequestSaga() {
  const categoriesResponse = yield call(fetchCategories);
  if (Array.isArray(categoriesResponse)) {
    yield put({
      type: ActionTypes.GetCategoriesSuccess,
      payload: assignCategories(categoriesResponse),
    });
  } else {
    yield put({
      type: ActionTypes.GetCategoriesError,
    });
  }
}

export function* getServiceRequestIdSaga(action) {
  const userProfileAndSettings = yield select(getUserProfileAndSettings);
  const dataToSend = {
    requestId: action.payload,
    lotId: userProfileAndSettings.activeLotId,
  };
  const serviceRequestResponse = yield call(getServiceRequestId, dataToSend);
  if (serviceRequestResponse.errors) {
    yield put({
      type: ActionTypes.ApiFatalError,
      payload: {
        link: '/dashboard',
        text: 'Back To Dashboard',
      },
    });
  } else {
    yield put({
      type: ActionTypes.GetServiceRequestIdSuccess,
      payload: serviceRequestResponse,
    });
  }
}

async function completeServiceRequest(possibleRequestId) {
  const { lotId } = possibleRequestId;

  try {
    return await _sendRequest(possibleRequestId, WarrantyService.completeServiceRequest, true);
  } catch (error) {
    return buildErrorObject(error);
  }
}

export function* completeServiceRequestSaga() {
  const warrantyState = yield select(getWarranty);
  const { requestId } = warrantyState.warrantyClaims;
  const userProfileAndSettings = yield select(getUserProfileAndSettings);
  const { activeLotId: lotId } = userProfileAndSettings;
  if (requestId === 'requested') {
    // extra safeguard
    yield put({
      type: ActionTypes.ApiFatalError,
      payload: {
        link: '/dashboard',
        text: 'Back To Dashboard',
      },
    });
  } else {
    const dataToSend = {
      serviceRequestId: requestId,
      lotId,
    };
    const res = yield call(completeServiceRequest, dataToSend);
    if (res.errors) {
      yield put({
        type: ActionTypes.ApiFatalError,
        payload: {
          link: '/dashboard',
          text: 'Back To Dashboard',
        },
      });
    } else {
      yield put({
        type: ActionTypes.ServiceRequestCompleted,
      });
    }
  }
}

export function* categoryDataRequestSaga(action) {
  const data = {
    categoryId: action.payload.categoryId,
    categoryName: action.payload.categoryName,
    categoryTooltip: action.payload.categoryTooltip,
  };
  const serviceItems = yield select(getWarrantyServiceItems);
  yield put({
    type: ActionTypes.AddClaim,
    payload: {
      categoryId: data.categoryId,
      categoryName: data.categoryName,
      categoryTooltip: data.categoryTooltip,
      categoryData: [],
      selectedSubCategory: '',
      selectedIssue: '',
      modelNumber: '',
      comments: '',
      images: [{}, {}, {}, {}, {}, {}],
      serviceItems,
    },
  });
  const categoryDataResponse = yield call(fetchCategoryData, data);
  if (categoryDataResponse) {
    yield put({
      type: ActionTypes.UpdateClaimDetails,
      payload: {
        type: 'categoryData',
        value: categoryDataResponse,
        id: data.categoryId,
      },
    });
  } else {
    yield put({
      type: ActionTypes.GetCategoryDataError,
    });
  }
}

export function* saveClaimSaga(action) {
  const user = yield select(getUser);
  const userProfileAndSettings = yield select(getUserProfileAndSettings);
  const warrantyState = yield select(getWarranty);
  const obj = {
    claimsData: warrantyState.warrantyClaims.claimsData,
    activeCategories: warrantyState.warrantyDetails.activeCategories,
    openClaim:
      action && action.payload && action.payload.openClaim
        ? action.payload.openClaim
        : warrantyState.warrantyDetails.openClaimForm,
    pageStatus:
      action && action.payload && action.payload.page
        ? action.payload.page
        : warrantyState.warrantyDetails.pageStatus,
  };
  try {
    Utils.setLocalStorage(
      Utils.getWarrantyClaimName(
        user.user.sitecoreId,
        userProfileAndSettings.activeLotId
      ),
      JSON.stringify(obj)
    );
    yield put({
      type: ActionTypes.SaveClaimDataToFileSuccess,
    });
  } catch (e) {
    yield put({
      type: ActionTypes.SaveClaimDataToFileError,
    });
  }
}

export function* submitWarrantyClaimsSaga() {
  const warrantyState = yield select(getWarranty);
  const { claimsData } = warrantyState.warrantyClaims;
  // eslint-disable-next-line no-restricted-syntax
  for (const i in claimsData) {
    if (
      Utils.isNumeric(i) &&
      claimsData[i].submissionStatus !== 'submitted'
    ) {
      const t = performance.now();
      yield put({
        type: ActionTypes.ServiceItemSubmitRequest,
        payload: {
          idOrIndex: parseInt(i, 10),
          time: t,
        },
      });

      const submitClaimResponse = yield call(
        handleSubmitWarrantyClaimSaga,
        i,
      );
      if (
        submitClaimResponse &&
        submitClaimResponse.serviceRequestId &&
        submitClaimResponse.serviceItemId
      ) {
        yield put({
          type: ActionTypes.ServiceItemSubmitSuccess,
          payload: {
            idOrIndex: parseInt(i, 10),
            time: performance.now(),
          },
        });
      } else {
        yield put({
          type: ActionTypes.ApiFatalError,
          // payload: {
          //     link: '/dashboard',
          //     text: 'Back To Dashboard',
          // },
        });
      }
    }
  }
  yield put({
    type: ActionTypes.ServiceItemBatchSubmitSuccess,
  });
}

export function* removeClaimSaga(action) {
  const { categoryId } = action.payload;
  const warrantyState = yield select(getWarranty);
  const { claimsData } = warrantyState.warrantyClaims;

  const claimToRemove = claimsData.find((claim) => {
    return claim.categoryId === categoryId;
  });

  yield put({
    type: ActionTypes.RemoveClaim,
    payload: claimToRemove,
  });
}

export function* removeExistingClaimsDraftSaga() {
  const user = yield select(getUser);
  const userProfileAndSettings = yield select(getUserProfileAndSettings);
  Utils.removeLocalStorage(
    Utils.getWarrantyClaimName(
      user.user.sitecoreId,
      userProfileAndSettings.activeLotId
    )
  );
  yield put({
    type: ActionTypes.HideClaimDraftModal,
  });
}
