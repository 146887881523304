import slugify from 'slugify';
import React from 'react';
import { Icon } from 'ComponentLibrary';
import Constants from 'Constants';
/* globals appInsights */

const sizeKeys = {
  original: '50033',
  300: '50036',
  600: '50035',
  900: '50100',
  1200: '50034',
  1800: '50060',
};


export function appInsightJsonDump(data) {
  try {
    appInsights.trackEvent({
      name: 'json-dump',
      properties: data
    });
  } catch (e) {
    console.log('error sending app insight json dump')
  }
}

// export function appInsightError(error) {

//   try {
//     appInsights.trackEvent({
//       name: 'error',
//       properties: {
//         message: error.message,
//         name: error.name,
//       }
//     });
//   } catch (e) {
//     console.log('error sending app insight error')
//   }
// }

// export function appInsightEvent(eventName, lot = null, serviceRequestId = null) {

//   try {
//     if (lot || serviceRequestId) {
//       appInsights.trackEvent({
//         name: eventName,
//         properties: {
//           lot,
//           serviceRequestId,
//         }
//       });
//     } else {
//       appInsights.trackEvent({
//         name: eventName,
//       });
//     }
//   } catch (e) {
//     console.log('error sending app insight event')
//   }
// }

export const getPlainDateAndTime = (dateStringWithTimestampFromSite) => {
  if (
    !dateStringWithTimestampFromSite ||
    dateStringWithTimestampFromSite.indexOf('T') < 0
  )
    return null;
  const dateParts = dateStringWithTimestampFromSite.split('T')[0];
  const timeParts = dateStringWithTimestampFromSite.split('T')[1];
  const year = dateParts.split('-')[0].substr(2, 2);
  const month = dateParts.split('-')[1];
  const day = dateParts.split('-')[2];
  const dateString = `${month}/${day}/${year}`; //d ? `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear().toString().substr(2)}` : '';
  const hours = timeParts.split(':')[0];
  const minutes = timeParts.split(':')[1];
  const timeString = `${fixHours(hours)}:${fixMinutes(minutes)}${parseInt(hours) < 12 ? 'a' : 'p'
    }m`;
  return dateStringWithTimestampFromSite
    ? `${dateString} at ${timeString}`
    : null;
};

const nums = new RegExp(/^[0-9]+$/);

export const fixHours = (hours) => {
  let newHours = parseInt(hours);
  if (hours === 0 || newHours === 0) {
    return 12;
  }
  if (newHours >= 13) {
    newHours = hours - 12;
  }
  return newHours;
};
export const fixMinutes = (minutes) => {
  minutes = parseInt(minutes);
  if (minutes < 10) {
    return `0${minutes}`;
  }
  return minutes.toString();
};

export const getPageTitle = (tabData) => {
  const currentURL = window.location.href;
  let page =
    currentURL.indexOf('#') >= 0
      ? currentURL.split('#')[0].split('/').pop()
      : currentURL.split('/').pop();
  if (nums.test(page)) {
    const arry = currentURL.split('/');
    page = arry[arry.length - 2];
  }
  if (
    page !== 'dashboard' &&
    Constants.pages[page] &&
    Constants.pages[page].pageTitle
  ) {
    return Constants.pages[page].pageTitle;
  }
  if (Array.isArray(tabData) && tabData[0] !== 'r') {
    let ret = null;
    tabData.forEach(({ stageKey, status }) => {
      if (
        ret === null &&
        status === 'Started' &&
        Constants.pages[stageKey] &&
        Constants.pages[stageKey].pageTitle
      ) {
        ret = Constants.pages[stageKey].pageTitle;
      }
    });
    if (ret !== null) {
      return ret;
    }
  }
  const defaultPage = Constants.pages.dashboard.pageTitle;
  return defaultPage;
};

export const formatPhone = (phoneNumber) =>
  phoneNumber.match(/\d{10}/g)
    ? `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(
      3,
      3
    )}-${phoneNumber.substr(6, 4)}`
    : phoneNumber;

const Utils = {
  getWarrantyClaimName: (sitecoreId, lotId) =>
    `warranty-claim-${lotId}-${sitecoreId}`,
  hashCode: (str, asString = true, seed = undefined) => {
    /*jshint bitwise:false */
    var i,
      l,
      hval = seed === undefined ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
      hval ^= str.charCodeAt(i);
      hval +=
        (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    if (asString) {
      // Convert to 8 digit hex string
      return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
    }
    return hval >>> 0;
  },
  findIndex: (hash, images) => {
    let toRet = [null, null];
    if (!images) {
      return toRet;
    }
    images.forEach((image, index) => {
      if (Utils.hashCode(image.photoLink) === hash) {
        toRet = [index, image.caption];
      }
    });
    return toRet;
  },
  getFilteredPhotos: (images, tagsForFiltering) => {
    return (
      images &&
      images.filter((image) => {
        return (
          image.photoLink !== null &&
          (tagsForFiltering === null ||
            tagsForFiltering === undefined ||
            (tagsForFiltering && tagsForFiltering.length === 0) ||
            (tagsForFiltering &&
              tagsForFiltering.includes(`${image.tag1}-${image.tag2}`)))
        );
      })
    );
  },
  translateStatus: (str) => {
    if (str && str.toLowerCase() === 'started') {
      return 'in progress';
    }
    if (str && str.toLowerCase() === 'notstarted') {
      return 'not started';
    }
    return str;
  },
  convertToRepCardData: (obj) => {
    if (!obj) return null;
    const {
      cellPhoneNumber,
      workPhoneNumber,
      email,
      profileImage,
      firstName,
      lastName,
      buyerPortalSummary,
      contactTitle,
      displayName,
      bio,
      imageCaption,
    } = obj;
    return {
      repImageUrl: profileImage,
      repName: displayName || `${firstName} ${lastName}`,
      repTitle: contactTitle,
      repDescription: buyerPortalSummary,
      repPhone: cellPhoneNumber || workPhoneNumber || null,
      repEmail: email,
      repContactLink: bio,
      repImageAltText: imageCaption,
      isCell: cellPhoneNumber !== null && cellPhoneNumber !== '' ? true : false,
    };
  },
  focusable:
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
  cssValueToPixel: (valstring) => {
    if (valstring.includes('px')) {
      return parseInt(valstring.replace('px', ''), 10);
    }
    return NaN;
  },
  month(date, isJSDate = true) {
    switch (isJSDate ? date.getMonth().toString() : date.toString()) {
      case '0':
        return 'January';
      case '1':
        return 'February';
      case '2':
        return 'March';
      case '3':
        return 'April';
      case '4':
        return 'May';
      case '5':
        return 'June';
      case '6':
        return 'July';
      case '7':
        return 'August';
      case '8':
        return 'September';
      case '9':
        return 'October';
      case '10':
        return 'November';
      case '11':
        return 'December';
      default:
        return '';
    }
  },
  day(date) {
    switch (date.getDay().toString()) {
      case '0':
        return 'Sunday';
      case '1':
        return 'Monday';
      case '2':
        return 'Tuesday';
      case '3':
        return 'Wednesday';
      case '4':
        return 'Thursday';
      case '5':
        return 'Friday';
      case '6':
        return 'Saturday';
      default:
        return '';
    }
  },
  dayAsInteger(date) {
    const formattedDay = date.getDate();
    if (formattedDay < 10) {
      return '0' + formattedDay;
    }

    return formattedDay;
  },
  monthAsInteger(date) {
    const formattedMonth = date.getMonth() + 1;
    if (formattedMonth < 10) {
      return '0' + formattedMonth;
    }

    return formattedMonth;
  },
  sortTasks(arrayOfTasks) {
    return arrayOfTasks.sort((a, b) => {
      return a.sortOrder < b.sortOrder ? -1 : 1;
    });
  },
  sortNotifications(notificationsArray) {
    return notificationsArray.sort((a, b) => {
      if (!a.viewedDate && b.viewedDate) {
        return -1;
      }
      if (a.viewedDate && !b.viewedDate) {
        return 1;
      }
      let aParts = a.sentDate.split('T');
      let bParts = b.sentDate.split('T');
      if (a.viewedDate && b.viewedDate) {
        aParts = a.viewedDate.split('T');
        bParts = b.viewedDate.split('T');
      }

      if ((a.viewedDate && b.viewedDate) || (a.sentDate && b.sentDate)) {
        const aDateParts = aParts[0].split('-');
        const bDateParts = bParts[0].split('-');
        const aTimeParts = aParts[1].split(':');
        const bTimeParts = bParts[1].split(':');
        const aDate = new Date();
        aDate.setUTCFullYear(aDateParts[0]);
        aDate.setUTCMonth(parseInt(aDateParts[1], 10));
        aDate.setUTCDate(aDateParts[2]);
        aDate.setUTCHours(aTimeParts[0]);
        aDate.setUTCSeconds(aTimeParts[1]);
        aDate.setUTCMilliseconds(aTimeParts[2]);

        const bDate = new Date();
        bDate.setUTCFullYear(bDateParts[0]);
        bDate.setUTCMonth(parseInt(bDateParts[1], 10));
        bDate.setUTCDate(bDateParts[2]);
        bDate.setUTCHours(bTimeParts[0]);
        bDate.setUTCSeconds(bTimeParts[1]);
        bDate.setUTCMilliseconds(bTimeParts[2]);

        return aDate.getTime() > bDate.getTime() ? -1 : 1;
      }
      return 0;
    });
  },
  sortUnreadNotifications(notificationsArray) {
    return notificationsArray.filter(
      (notification) => !notification.viewedDate
    );
  },
  isNumeric: (val) => nums.test(val),
  getCookie(name, decode = true) {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? (decode ? decodeURIComponent(v[2]) : v[2]) : null;
  },
  fixImageUrl(url) {
    let isDam = false;
    let urlArray = [];
    let toBeReplaced = null;
    Object.keys(sizeKeys).forEach((key) => {
      const piece = `-${sizeKeys[key]}/`;
      if (url.indexOf(piece) >= 0) {
        isDam = true;
        toBeReplaced = piece;
      }
      if (key !== 'original') {
        const width = key;
        urlArray.push(
          `${url}${url.indexOf('?') < 0 ? '?' : '&'}${width ? `mw=${width}` : ''
          }${width ? '&' : ''}${width ? ` ${width}w` : ''}`
        );
      }
    });

    if (isDam) {
      urlArray = [];
      Object.keys(sizeKeys).forEach((key) => {
        if (key !== 'original') {
          const newUrl = `${url.replace(toBeReplaced, `-${sizeKeys[key]}/`)}?`;
          urlArray.push(`${newUrl} ${key}w`);
        }
      });

      return urlArray.join(', ').trim();
    }
    return urlArray.join(', ').trim();
  },
  slugify(strPass) {
    if (!strPass) {
      return '';
    }
    const str = strPass.replace('/', '-').replace('(', '').replace(')', '');
    return slugify(str, { lower: true, strict: true });
  },
  getIndicator(status) {
    const plainStatus = Utils.slugify(status);
    let indicatorClasses = `status-indicator status-indicator--${Utils.slugify(
      status
    )}`;
    if (plainStatus === 'started') {
      indicatorClasses += ' status-indicator--started status-indicator--plain';
      return (
        <svg
          data-testid='started-indicator'
          className={indicatorClasses}
          width='14'
          height='14'
          viewBox='0 0 14 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='7' cy='7' r='4.899' stroke='#EDEDED' stroke-width='3' />
          <path
            d='M5.732 11.732a4.899 4.899 0 101.314-9.63'
            stroke='#FFD660'
            stroke-width='4'
          />
        </svg>
      );
    }
    if (plainStatus === 'complete') {
      return (
        <Icon
          testId='complete-indicator'
          size={0.75}
          color='white'
          icon='thickCheckmark'
          extraClasses={indicatorClasses}
        />
      );
    }

    indicatorClasses = `${indicatorClasses}`;
    return (
      <i data-testid='not-started-indicator' className={indicatorClasses}>
        {status}
      </i>
    );
  },
  findParent(element, className) {
    let match = false;
    let checker = element;
    while (!match) {
      if (!checker) {
        return null;
      }
      checker = checker.parentNode;
      if (
        checker &&
        checker.classList &&
        checker.classList.contains(className)
      ) {
        match = true;
      }
    }
    return checker;
  },
  setLocalStorage(key, value) {
    localStorage.setItem(key, value);
  },
  getLocalStorage(key) {
    return localStorage.getItem(key);
  },
  removeLocalStorage(key) {
    return localStorage.removeItem(key);
  },
  trimResponseUrl(url) {
    return url.split('?')[0];
  },
  findRedirectType(url) {
    const possibleTypes = ['envision', 'warranty', 'wellsfargo'];
    let redirectType = 'general';

    possibleTypes.forEach((type) => {
      if (url.indexOf(type) > -1) {
        redirectType = type;
      }
    });

    return redirectType;
  },
  formatPhone,
  formatDollarAmount(total) {
    return new Intl.NumberFormat().format(total);
  },
  replacePhoneNumbers: (str) =>
    str.replace(/\d{10}/gm, (match) => formatPhone(match)),
};

export function cleanHash() {
  if (
    window.location.hash === '#progress' ||
    window.location.hash === '#first-design-scheduling'
  ) {
    window.location.hash = '';
  }
}

export default Utils;
