import React, { Suspense } from 'react';
import {
  triggerEnvisionExternalRedirectAction,
  triggerWarrantyExternalRedirectAction,
  triggerWellsFargoExternalRedirectAction,
} from 'redux/actions/ExternalRedirect/ExternalRedirectActions';

import Login from 'pages/Login';
import Invite from 'pages/Invite';
import FindLot from 'pages/FindLot';
import Dashboard from 'pages/Dashboard';
import ExternalRedirect from 'pages/ExternalRedirect';
import Missing from 'pages/404';
import WithRedirect from 'utilities/withRedirect';
const LazyLoadWithTimeout = (moduleImport) =>
  React.lazy(() => {
    return Promise.all([
      moduleImport(),
      new Promise((resolve) => setTimeout(resolve, 300)),
    ]).then(([module]) => module);
  });

let LotAssociation = LazyLoadWithTimeout(() =>
  import('pages/LotAssociation')
);
const Progress = LazyLoadWithTimeout(() => import('pages/Progress'));
const UserSettings = LazyLoadWithTimeout(() => import('pages/UserSettings'));
const Contacts = LazyLoadWithTimeout(() => import('pages/Contacts'));
const Documents = LazyLoadWithTimeout(() => import('pages/Documents'));
const Checklist = LazyLoadWithTimeout(() => import('App/ContentComponents/Checklist'));
const Warranty = LazyLoadWithTimeout(() => import('pages/Warranty'));
const Deposits = LazyLoadWithTimeout(() => import('pages/Deposits'));
const PhotoGallery = LazyLoadWithTimeout(() => import('pages/PhotoGallery'));
const DivisionDashboard = LazyLoadWithTimeout(() =>
  import('pages/DivisionDashboard')
);
const DesignSelections = LazyLoadWithTimeout(() =>
  import('pages/DesignSelections')
);
const LotDashboard = LazyLoadWithTimeout(() => import('pages/LotDashboard'));

// eslint-disable-next-line max-len
// This is the configuration object that is used by the <BuyerPortalRouter /> component to automatically configure each declared route.
// eslint-disable-next-line max-len
// Each route requires a path string and a component reference. Optionally, a nested routes property can be created to achieve sub-routes.

//const createElement =

const routeConfig = [
  {
    path: '/',
    element: <WithRedirect path="/" page="login"><Login /></WithRedirect>,
    page: 'login',
    exact: true,
  },
  {
    path: '/login',
    element: <WithRedirect path="/login" page="login"><Login /></WithRedirect>,
    page: 'login',
  },
  {
    path: '/invite',
    element: <WithRedirect path='/invite' page="invite"><Invite /></WithRedirect>,
    page: 'invite',
  },
  {
    path: '/checklist/:taskId?',
    element: <Suspense fallback={<></>}><Checklist mode='page' /></Suspense>,
    page: 'dashboard',
  },
  {
    path: '/find-lot',
    element: <WithRedirect path="/find-lot" page="findLot"><FindLot /></WithRedirect>,
  },
  {
    path: '/dashboard/',
    children: [
      {
        path: "checklist/:taskId?",
        element: <WithRedirect path="/dashboard/checklist/:taskId?" page="dashboard"><Dashboard /></WithRedirect>,
      },
      { path: ":tab?/:constructionStepId?", element: <WithRedirect path='/dashboard/:tab?/:constructionStepId?' page="dashboard"><Dashboard /></WithRedirect> },
    ],
  },
  {
    path: '/lot-association',
    element: <WithRedirect path="/lot-association" page="lotAssociation"><Suspense fallback={<></>}><LotAssociation /></Suspense></WithRedirect>,
  },
  {
    path: '/photos/:hash?',
    //component: PhotoGallery,
    element: <WithRedirect path="/photos/:hash?" page="dashboard"><Suspense fallback={<></>}><PhotoGallery /></Suspense></WithRedirect>,
    //page: 'dashboard',
  },
  {
    path: '/photos/:hash?',
    element: <WithRedirect page="dashboard"><PhotoGallery /></WithRedirect>,
  },
  {
    path: '/profile',
    element: <WithRedirect path="/profile" page="dashboard"><Suspense fallback={<></>}><UserSettings /></Suspense></WithRedirect>,
  },
  {
    path: '/contacts',
    element: <WithRedirect path="/contacts" page="dashboard"><Suspense fallback={<></>}><Contacts /></Suspense></WithRedirect>,
  },
  {
    path: '/documents',
    element: <WithRedirect page="dashboard" path="/documents"><Suspense fallback={<></>}><Documents /></Suspense></WithRedirect>,
  },
  {
    path: '/progress/:tab?/:constructionStepId?',
    element: <WithRedirect page="dashboard" path='/progress/:tab?/:constructionStepId?'><Suspense fallback={<></>}><Progress /></Suspense></WithRedirect>,
  },
  {
    path: '/checklist/:taskId?',
    element: <WithRedirect page="dashboard" path="'/checklist/:taskId?'"><Suspense fallback={<></>}><Checklist mode="page" /></Suspense></WithRedirect>,
  },
  {
    path: '/division-dashboard',
    element: <WithRedirect page="dashboard" path='/division-dashboard' adminOnly><Suspense fallback={<></>}><DivisionDashboard /></Suspense></WithRedirect>,
  },
  {
    path: '/lot-dashboard',
    element: <WithRedirect path="/lot-dashboard" page="dashboard"><Suspense fallback={<></>}><LotDashboard adminOnly /></Suspense></WithRedirect>,
  },
  {
    path: '/envision',
    element: (
      <WithRedirect page="externalRedirect" path="/envision">
        <ExternalRedirect
          actionCreator={triggerEnvisionExternalRedirectAction}
        />
      </WithRedirect>
    ),
  },
  {
    path: '/wellsfargo/:depositId',
    element: <WithRedirect path="/wellsfargo/:depositId" page="externalRedirect"><ExternalRedirect actionCreator={triggerWellsFargoExternalRedirectAction} /></WithRedirect>,
  },
  {
    path: '/warranty/manual',
    element: <WithRedirect path="/warranty/manual" page="externalRedirect"><ExternalRedirect actionCreator={triggerWarrantyExternalRedirectAction} /></WithRedirect>,
  },
  {
    path: '/designcenter/confirmedselections',
    element: <WithRedirect path='/designcenter/confirmedselections' page="dashboard"><Suspense fallback={<></>}><DesignSelections /></Suspense></WithRedirect>,

  },
  {
    path: '/financing/loanapplication',
    element: <WithRedirect path="/financing/loanapplication" page="externalRedirect "><ExternalRedirect redirectUrl={'{$financingLink}'} /></WithRedirect>,
  },
  {
    path: '/warranty',
    element: <WithRedirect path="/warranty" page="dashboard"><Suspense fallback={<></>}><Warranty /></Suspense></WithRedirect>,
  },
  {
    path: '/deposits',
    element: <WithRedirect path="deposits" page="dashboard"><Suspense fallback={<></>}><Deposits /></Suspense></WithRedirect>,
  },
  {
    path: '/design-selections',
    element: <WithRedirect path="/design-selections" page="dashboard"><DesignSelections /></WithRedirect>,
  },
  {
    path: '*',
    element: <WithRedirect path="*" page="login"><Missing /></WithRedirect>,
  },
];

export default routeConfig;
