import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, useRoutes } from 'react-router-dom';

import { NavHeader, LoadingScreen, FatalError } from 'ComponentLibrary';
import UserProfileDropdown from 'App/ContentComponents/UserProfileDropdown';
import ChecklistDropdown from 'App/ContentComponents/ChecklistDropdown';
import NotificationsDropdown from 'App/ContentComponents/NotificationsDropdown';
import Checklist from 'App/ContentComponents/Checklist';
import AnchoredMobileNav from 'App/ContentComponents/AnchoredMobileNav';
import VideoModal from 'App/ContentComponents/VideoModal';
import ContactBioModal from 'App/ContentComponents/ContactBioModal';
import FeedbackModal from 'App/ContentComponents/FeedbackModal';
import HamburgerMenu from 'App/ContentComponents/HamburgerMenu';
import AcuityModal from 'App/ContentComponents/AcuityModal';
import PhotoModal from 'App/ContentComponents/Photos/PhotoModal';
import ShareModal from 'App/ContentComponents/ShareModal';
import routeConfig from 'routeConfig';
import { createRefreshFromCookieAction } from 'redux/actions/Login/LoginActions';
import {
  useHaveUser,
  useHaveFatalError,
  useIsAdmin,
} from 'utilities/HooksAndSelectors';
import AnalyticsListener from 'components/Analytics';
import './BuyerPortalRouter.scss';


const BuyerPortalRouter = () => {
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const haveUser = useHaveUser();
  const user = useSelector((reduxState) => reduxState.userState);
  const bio = useSelector((reduxState) => reduxState.contactBioState);
  const videoModalState = user.videoModal;
  const isExternalRedirect = user.userDetails.currentPage === 'externalRedirect';

  let showAnchoredNav =
    haveUser &&
    user &&
    user.userDetails &&
    user.userDetails.lotAssociations &&
    user.userDetails.lotAssociations.length > 0 &&
    user.userDetails.currentPage === 'dashboard';

  if (isAdmin && user.userDetails.currentPage === 'dashboard') {
    showAnchoredNav = true;
  }

  if (
    (!haveUser || !user) &&
    user.userDetails.refreshCookie !== 'expired' &&
    user.userDetails.refreshCookie !== 'checking' &&
    user.userDetails.refreshCookie !== 'refreshed'
  ) {
    if (
      window.location.search.indexOf('logout=true') < 0 &&
      window.location.pathname.indexOf('invite') < 0
    ) {
      const action = createRefreshFromCookieAction();
      dispatch(action);
    }
  }
  // if token is refreshed or expired, or if page is invite and we have checked the token status, hide load screen
  const exit = !!(
    (user &&
      user.userDetails &&
      user.userDetails.refreshCookie &&
      (user.userDetails.refreshCookie === 'expired' ||
        user.userDetails.refreshCookie === 'refreshed')) ||
    (window.location.pathname.indexOf('invite') > -1 &&
      user.userDetails.inviteTokenStatus !== undefined)
  );

  const showFatalError = useHaveFatalError();

  return (
    <BrowserRouter>
      <AnalyticsListener trackingCode={window._env_.REACT_APP_GA_TRACKING_CODE}>
        <NavHeader
          links={[
            { path: '/dashboard', name: 'Dashboard' },
            { path: '/photos', name: 'Photos' },
            { path: '/contacts', name: 'Contacts' },
            { path: '/deposits', name: 'Deposits' },
            { path: '/documents', name: 'Documents' },
          ]}
        />
        <HamburgerMenu />
        {showAnchoredNav && <HamburgerMenu />}
        {showAnchoredNav && <AnchoredMobileNav />}
        <MyRoutes />
        <ChecklistDropdown />
        <NotificationsDropdown />
        <UserProfileDropdown />
        <Checklist />
        <VideoModal {...videoModalState} />
        <FeedbackModal />
        <PhotoModal />
        <ShareModal />
        {bio && <ContactBioModal />}
        <LoadingScreen
          exit={isExternalRedirect ? false : exit}
          minimumTime={3000}
          variant={isExternalRedirect ? 'external-redirect' : null}
          color={
            user && user.userDetails ? user.userDetails.loadScreenColor : null
          }
          customPhrasing={
            isExternalRedirect ? user.userDetails.loadingScreenPhrasing : []
          }
        />
        {showFatalError && <FatalError error={user.userDetails.errors} />}
        <AcuityModal />
      </AnalyticsListener>
    </BrowserRouter >
  );
};

const MyRoutes = () => useRoutes(routeConfig);

export default BuyerPortalRouter;
