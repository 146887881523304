/* eslint-disable indent */
import ActionTypes from 'redux/actions/ActionTypes';

const initialState = {
    profileDropDownOpen: false,
    activeLotId: null,
    checklistModalOpen: false,
    focusedTask: null,
    removeLotMode: false,
    lotForDeletion: null,
    gettingExternaLink: false,
};

const ProfileAndSettingsReducer = (state = initialState, action) => {
    if (!action) {
        return state;
    }
    let newActiveLotId = state.activeLotId;
    switch (action.type) {
        case ActionTypes.FetchExternalRedirectError:
        case ActionTypes.FetchExternalRedirectNoUrl:
            return {
                ...state,
                gettingExternaLink: false,
            }
        case ActionTypes.StartExternalRedirect:
            return {
                ...state,
                gettingExternaLink: true,
            }
        case ActionTypes.GetLotById:
            return {
                ...state,
                activeLotId: action.payload.lotId,
            };
        case ActionTypes.LotDeletionSuccess:
            if (
                action.payload.deletedLot === state.activeLotId ||
                action.payload.deletedLot.toString() === state.activeLotId
            ) {
                newActiveLotId = action.payload.lotAssociations[0].lotId;
            }
            return {
                ...state,
                removeLotMode: false,
                activeLotId: newActiveLotId,
                lotForDeletion: null,
            };
        case ActionTypes.DeleteLotAssociation:
            return {
                ...state,
                lotForDeletion: action.payload,
            };
        case ActionTypes.EnterRemoveLotMode:
            return {
                ...state,
                removeLotMode: true,
            };
        case ActionTypes.ExitRemoveLotMode:
            return {
                ...state,
                lotForDeletion: null,
                removeLotMode: false,
            };
        case ActionTypes.GoToAddLot:
            return {
                ...state,
                profileDropDownOpen: false,
            };
        case ActionTypes.AppIsInstallable:
            return {
                ...state,
                appIsInstallable: true,
            };
        case ActionTypes.LogOut:
            return initialState;
        case ActionTypes.ChangeActiveLot:
            return {
                ...state,
                activeLotId: action.payload,
                profileDropDownOpen: false,
                lotForDeletion: null,
                removeLotMode: false,
                gettingExternaLink: false,
            };
        case ActionTypes.LotIdSuccess:
            return { ...state, activeLotId: action.payload.newLotId };
        case ActionTypes.LoginSuccess:
            if (
                state.activeLotId === null &&
                action.payload.lotAssociations &&
                action.payload.lotAssociations[0]
            ) {
                const possibleLotId = action.payload.possibleLotId;
                const possibles = action.payload.lotAssociations.filter(
                    ({ lotId }) => lotId === parseInt(possibleLotId, 10)
                );
                const isValidLotId = possibles.length === 1;
                // grab the active lot id here from the first
                const ret = {
                    ...state,
                    activeLotId: isValidLotId
                        ? possibleLotId
                        : action.payload.lotAssociations[0].lotId,
                };
                return ret;
            }
            if (action.payload.activeLotId) {
                return {
                    ...state,
                    activeLotId: action.payload.activeLotId,
                };
            }
            return state;
        case ActionTypes.SignOut:
            return {
                ...state,
                profileDropDownOpen: false,
                lotForDeletion: null,
                removeLotMode: false,
            };
        case ActionTypes.OpenChecklistModal:
            return {
                ...state,
                checklistModalOpen: true,
                focusedTask: action.payload,
            };
        case ActionTypes.CloseFindLotPage:
            return {
                ...state,
                activeLotId: action.payload === undefined ? null : action.payload,
            };
        case ActionTypes.CloseChecklistModal:
            return {
                ...state,
                focusedTask: null,
                checklistModalOpen: false,
            };
        case ActionTypes.OpenUserProfileDropdown:
            return {
                ...state,
                profileDropDownOpen: true,
            };
        case ActionTypes.CloseUserProfileDropdown:
            return {
                ...state,
                profileDropDownOpen: false,
                lotForDeletion: null,
                removeLotMode: false,
            };

        case ActionTypes.FetchExternalRedirectSuccess:
            return {
                ...state,
                redirectUrl: action.payload,
            };

        case ActionTypes.OpenFindLotPage:
            return {
                ...state,
                profileDropDownOpen: false,
            };

        default:
            return state;
    }
};

export default ProfileAndSettingsReducer;
